a:hover {
  text-decoration: underline;
}


.removeLinkUnderline:hover {
  text-decoration: none !important;
}

::-moz-selection {
  /* Code for Firefox */
  color: #fff;
  background: #19235790;
}

::selection {
  color: #fff;
  background: #19235790;
}

.cartItem:first-child {
  position: relative;

  .cartItem-image::before {
    position: absolute;
    top: -22px;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 500;
    color: #192357;
    margin-left: -5px;
  }

  .cartItem-title::before {
    position: absolute;
    top: -22px;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 500;
    color: #192357;
  }

  .cartItem-amount::before {
    position: absolute;
    top: -80px;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 500;
    color: #192357;
    margin-left: 35px;
  }

  .cartItem-subtotal::before {
    position: absolute;
    top: -79px;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 500;
    color: #192357;
    margin-left: -5px;
  }
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Roboto Condensed', sans-serif !important;
}

body {
  overflow-x: clip;

}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.scrollBar {

  /* width */
  ::-webkit-scrollbar {
    width: 6px;
    position: relative;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: none !important;
    background-color: transparent !important;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #192357 !important;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}


.fullDescription {
  a {
    text-decoration: underline;
  }

  * {
    font-family: inherit !important;
    color: inherit !important;
    height: fit-content;
  }

  strong {
    font-weight: 500;
  }

  table {
    background-color: none !important;
    height: fit-content !important;
  }

  table,
  td,
  tr,
  th {
    border: 0;
    text-align: center;
  }

  table {
    width: fit-content !important;
    max-width: 100%;
  }

  th {
    background-color: #192357;
    color: #fff !important;
  }

  td {
    background-color: #EFEFEF;
  }

  td,
  th,
  tr {
    padding: 5px 10px;
  }

  @media only screen and (max-width: 450px) {
    table {
      width: 100%;
      display: block;
      align-items: center;
      justify-content: center;
      font-size: 14px;
    }

    tbody {
      width: 100%;
      display: flex;
      flex-direction: row;
    }

    td,
    th {
      text-align: center;
      margin: 2px;
    }

    th {
      display: flex;
      min-height: 48px;
      height: auto;
      align-items: center;
      justify-content: center;
    }

    tr {
      width: 100%;
      padding: 0px;
      display: flex;
      flex-direction: column;
    }
  }

}


.image-gallery-thumbnails .image-gallery-thumbnails-container {
  display: flex !important;
  justify-content: space-between;
}


.image-gallery-thumbnail:hover {
  border-color: transparent !important;
}

.image-gallery-thumbnail.active {
  border-color: transparent !important;
  transform: scale(1.1);
  margin-top: 5px;
}

.image-gallery-left-nav {
  position: absolute;
  border: none;
  background-color: transparent;
  top: 50% !important;
  z-index: 9;
}

.image-gallery-right-nav {
  position: absolute;
  border: none;
  background-color: transparent;
  top: 50% !important;
  z-index: 9;
}

@media only screen and (max-width: 750px) {
  .image-gallery {
    margin-top: 30px;
  }
}


@media only screen and (min-width: 900px) {
  .image-gallery-content {
    padding: 0;
  }

}

.toGrayScale {
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}


.onCardHover {
  svg {
    width: 70px !important;
  }

  svg g path {
    fill: #5577A0 !important;
  }
}

.onCardHover:hover {
  background-color: #5577A0;

  p,
  h6 {
    color: #fff;
  }

  svg g path {
    fill: #fff !important;
  }
}


.innerHtml {
  color: #192357;

  td {
    background-color: #F8F8F8;
    padding: 10px 20px;
  }

  th {
    background-color: #EFEFEF;
    padding: 10px 20px;
    font-weight: 600;
  }

  table {
    border-spacing: 5px
  }

  li {
    align-items: center;
    padding: 10px 0px;
    list-style-image: url('/assets/images/Arrow.svg');
  }

  h2 {
    font-size: 18px;
    margin-top: 40px;
  }

  h3 {
    font-size: 16px;
    margin-top: 30px;
  }

  a {
    color: #5577A0;
    text-decoration: underline;
    word-break: break-all;
  }

  img {
    max-width: 100%;
  }
}

// html,
// body {
//   overflow-x: clip;
// }

.prevent-select {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.underlineOnHover:hover {
  text-decoration: underline;
}

.maxLines {
  overflow: hidden;
  max-height: 10.5ch;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.hideScrollBar {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
}

/* Firefox */
.hideScrollBar::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}


.lineThrough {
  text-decoration-line: line-through;
  -webkit-text-decoration-line: line-through;
  text-decoration-thickness: 2px;
  -webkit-text-decoration-line-thickness: 2px;
}